export const LATEST_STACK_VERSION = 1;
export const DEFAULT_IMAGE_FOLDER = "connectpath";
export const MEDIA_STREAM_TYPES = {
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
};
export const PARTNERS = {
  DIGITAL_ISLAND: "digitalisland",
  CDW: "cdw",
};
export const USERS_INTEGRATIONS_LIMIT = 300;
