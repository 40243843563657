export const isValidJSON = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    var json = JSON.parse(text);
    return typeof json === "object";
  } catch (error) {
    return false;
  }
};
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export function getClientDomain({ clientDomains, origin }) {
  const domains = clientDomains.split(",");

  if (origin) {
    for (const domain of domains) {
      if (origin.includes(domain)) {
        return domain;
      }
    }
  }

  return domains[0];
}
