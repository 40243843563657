export const ErrorMessages = Object.freeze({
  ERR0000: "Something went wrong",
  IN0001: "Error such an integration already exists",
  IN0002: "Something went wrong, the integration to update was not found",
  US0001: "The instance stack is already in its latest version",
  US0002: "There is a stack update already running",
  US0004: "The instance stack cannot be updated",
  CS0001: "The support access is being enabled",
  CS0002: "The support access is already enabled",
  CS0003: "There is no support access enabled for the instance account",
  CS0004: "The support access for the instance account is not fully enabled",
  US0003: "The instance stack have an incorrect status and cannot be updated",
  QR0001: "The connect backup version {0} doesn't exists",
  QR0002: "There is a connect backup restore already running",
  QR0003: "There is a sync for users already running, please wait untill it finishes",
  CH0001: "Attached files could not be uploaded",
  CH0002: "There is an error with SMPT server configuration",
  PC0001: "Error creating or updating the survey",
  PC0002: "Error fetching list of surveys",
  PC0003: "Error deleting the surveys",
  QC0001: "User Deletion has been failed as user is being used as quick connect",

  SI0001: "The custom stack integration id is required",
  SI0002: "The custom stack integration definition with given id doesn't exists",
  SI0003: "The custom stack integration deploy is already in latest version",
  SI0004: "The custom stack integration deploy with given id doesn't exists",
  SI0005: "The custom stack integration deploy status is not deployed",
  SI0006: "The custom stack is already deployed in your account, please remove it first",
  SI0007: "The custom stack deployed is missing in your account",
  SI0008: "The value for the template parameter '{0}' is missing",
  SI0009: "The value for the template parameter '{0}' is invalid",
});
