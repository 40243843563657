export const PeerCallStatus = {
  InitialState: "InitialState",
  Connecting: "Connecting",
  Connected: "Connected",
};

export const EndpointType = {
  phone_number: { description: "phone_number", icon: "fas fa-hashtag" },
  agent: { description: "agent", icon: "fas fa-user" },
  queue: { description: "queue", icon: "fas fa-layer-group" },
};

export const ContactFilter = {
  SMS: "sms",
  EMAIL: "email",
};

export const Engage = {
  Channel: {
    VOICE: "voice",
  },
  InitiationMethod: {
    OUTBOUND: "OUTBOUND",
  },
};

export const UserStatus = {
  Hold: "Hold",
  Busy: "Busy",
};

export const backupFileExpirationDays = 5;
